import type { Brands } from '@jagex-pp/rs-franchise-design'
import { SeoMeta } from '@jagex-pp/seo-meta'
import Head from 'next/head'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import type { Maybe, Seo as SeoEntry } from '~src/generated/graphql'

type Props = {
  seo?: Maybe<SeoEntry>
  title?: Maybe<string>
  brand: Brands
}

const BASE_URL = process.env.NEXT_PUBLIC_SITE_URL
  ? `https://${process.env.NEXT_PUBLIC_SITE_URL}`
  : process.env.NEXT_PUBLIC_VERCEL_URL
  ? `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`
  : undefined

const Seo = ({ title, seo, brand }: Props) => {
  const { t } = useTranslation()

  const seoTitle = seo?.title || title || t(`seo:${brand}.title`)!
  const description = seo?.description || t(`seo:${brand}.description`)!
  const ogImageUrl =
    seo?.ogImage?.url || new URL(t(`seo:${brand}.imageUrl`), BASE_URL ?? 'http://localhost').href!
  const twitterCard = seo?.twitterCard || t(`seo:${brand}.twitterCard`)!
  const twitterImageUrl =
    seo?.twitterImage?.url ||
    new URL(t(`seo:${brand}.imageUrl`), BASE_URL || 'http://localhost').href
  const twitterAccount = seo?.twitterAccount || t(`seo:${brand}.twitterAccount`)

  const router = useRouter()
  const url = typeof window === 'undefined' ? `${BASE_URL}${router.pathname}` : window.location.href

  return (
    <SeoMeta
      {...seo}
      title={seoTitle}
      description={description}
      robots={{ noIndex: seo?.noIndex, noFollow: seo?.noFollow }}
      url={url}
      fbAppId="1537817476525738"
      ogImage={seo?.ogImage || { url: ogImageUrl }}
      twitterCard={twitterCard}
      twitterImage={seo?.twitterImage || { url: twitterImageUrl }}
      twitterAccount={twitterAccount}
      Wrapper={Head}
    />
  )
}

export default Seo
