/** @jsxImportSource theme-ui */
import { GoogleTagManager } from '@jagex-pp/gtm/nextjs'
import { filterMaybe } from '@jagex-pp/misc-helpers'
import {
  Brands,
  FooterProps,
  LogoType,
  Navigation,
  PlatformDownloadLinkProps,
  useHasMounted,
} from '@jagex-pp/rs-franchise-design'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'
import { Flex, Heading } from 'theme-ui'

import Seo from '~components/Seo'
import { gtmConfig } from '~lib/config'
import type {
  Footer as FooterEntry,
  Maybe,
  Navigation as NavigationEntry,
  NavLink,
  Seo as SeoEntry,
  SocialLink,
} from '~src/generated/graphql'

import pegiIcon from './assets/pegi-16.svg'
import pegiDrugsIcon from './assets/pegi-drugs.svg'
import pegiPurchaseIcon from './assets/pegi-ingame-purchases.svg'

const Footer = dynamic<FooterProps>(() =>
  import('@jagex-pp/rs-franchise-design/lib/components/organisms/Footer/Footer').then(
    (design) => design.Footer,
  ),
)
const PreviewBanner = dynamic(() => import('~components/PreviewBanner'))

type BaseLayoutProps = {
  preview?: boolean
  children: React.ReactNode
  title?: Maybe<string>
  footer?: Maybe<FooterEntry>
  navigation?: Maybe<NavigationEntry>
  seo?: Maybe<SeoEntry>
  brand: Brands
  generatedDate: string
}

const pegiUrl =
  'https://pegi.info/search-pegi?q=runescape&op=Search&age%5B%5D=&descriptor%5B%5D=&publisher=Jagex+Ltd&platform%5B%5D=&release_year%5B%5D=&page=1&form_build_id=form-5Nc7D2ScBDuiMzP9F2ffxljtiSLFY38thQpDSDwRrq0&form_id=pegi_search_form'

export default function BaseLayout(props: BaseLayoutProps): JSX.Element {
  const { preview, children, footer, navigation, title, seo, brand, generatedDate } = props
  const mounted = useHasMounted()
  const { t } = useTranslation()

  return (
    <Flex sx={{ flexDirection: 'column', minHeight: '100vh' }}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <link rel="icon" href="/favicons/favicon.ico" />
        <link rel="apple-touch-icon" sizes="180x180" href="/favicons/apple-touch-icon.png" />
        <link
          rel="android-chrome-icon"
          sizes="192x192"
          href="/favicons/android-chrome-192x192.png"
        />
        <link
          rel="android-chrome-icon"
          sizes="512x512"
          href="/favicons/android-chrome-512x512.png"
        />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16.png" />
        <link rel="preconnect" href="https://vitals.vercel-insights.com" />
        <link rel="preconnect" href="https://consent.cookiebot.com" />
        <link rel="preconnect" href="https://www.youtube.com" />
      </Head>

      <GoogleTagManager {...gtmConfig} />

      <Seo seo={seo} title={title} brand={brand} />

      {preview && <PreviewBanner />}

      {navigation && (
        <Navigation
          rsLink={navigation.rsLink!}
          rsLinkTitle={navigation.rsLinkTitle!}
          osLink={navigation.osLink!}
          osLinkTitle={navigation.osLinkTitle!}
        />
      )}

      {children && (
        <Flex
          as="main"
          sx={{ position: 'relative', flexDirection: 'column', alignItems: 'center', flex: '1' }}
          id="main">
          {title && (
            <Heading
              as="h1"
              sx={{
                position: 'absolute',
                width: '1px',
                height: '1px',
                margin: '-1px',
                border: '0',
                padding: '0',
                clip: 'rect(0 0 0 0)',
                overflow: 'hidden',
              }}>
              {title}
            </Heading>
          )}

          {children}
        </Flex>
      )}
      {footer && (
        <Footer
          id={footer.name!}
          brand={(footer.brand as Brands) ?? undefined}
          copyright={footer.copyright ?? undefined}
          logoUrl={footer.logoUrl ?? undefined}
          navigationItems={footer.navigationItemsCollection?.items
            .filter((item): item is NavLink => filterMaybe(item))
            .map((item) => ({ children: item.title, href: item.url!, title: item.tooltip }))}
          socialLinks={
            footer.socialLinksCollection?.items
              .filter((item): item is SocialLink => filterMaybe(item))
              .map((item) => ({ title: item.logo as LogoType, url: item.url! })) || []
          }
          platformDownloadLinks={
            footer.platformListCollection?.items.map(
              (item): PlatformDownloadLinkProps => ({
                url: item?.url ?? '',
                title: item?.title ?? '',
                logo: (item?.platformLogo as LogoType) ?? undefined,
              }),
            ) ?? []
          }
          pegiIcons={[
            <Image src={pegiIcon} layout="intrinsic" alt={t('common:pegi-16-alt')} key="pegi-16" />,
            <Image
              src={pegiDrugsIcon}
              layout="intrinsic"
              alt={t('common:pegi-drugs-alt')}
              title={t('common:pegi-drugs-title')}
              key="pegi-drugs"
            />,
            <Image
              src={pegiPurchaseIcon}
              layout="intrinsic"
              alt={t('common:pegi-purchases-alt')}
              title={t('common:pegi-purchases-title')}
              key="pegi-purchases"
            />,
          ]}
          pegiUrl={pegiUrl}
          pegiTextLine={t('common:pegi-strap')}
        />
      )}
      <div aria-hidden="true" sx={{ display: 'none' }}>
        generated at: {generatedDate}
      </div>
      <div id="uptime-check-ssg" aria-hidden="true" sx={{ display: 'none' }}>
        ssg status ok
      </div>
      {mounted && (
        <div id="uptime-check-csr" aria-hidden="true" sx={{ display: 'none' }}>
          csr status ok
        </div>
      )}
    </Flex>
  )
}
